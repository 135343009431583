import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom';
import { FaPhone, FaEnvelope, FaLocationArrow } from "react-icons/fa"
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  return (

    <div className='footer flex flex-col'>
      <div className='footer-top border-b-2 border-yellow-500 flex flex-col items-center md:p-20 py-20 px-10  bg-black/[.6]'>
        <div className=' flex flex-col items-center mb-10 text-white'>
          <div className='text-2xl'>{t('con_ttl')}</div>
          <div>{t('fotr_1_ttl_txt')}</div>
        </div>
        <div className='md:grid md:grid-cols-2 md:gap-5 flex w-full flex-col mb-10'>
          <div className='md:col-span-1 flex flex-col w-full space-y-5'>
            <input className='input ' type="text" name="" id="" placeholder={t('fotr_1_name')} />
            <input className='input ' type="email" name="" id="" placeholder='E-Mail' />
            <input className='input ' type="tel" name="" id="" placeholder={t('fotr_1_tel')} />
          </div>
          <textarea className='input md:col-span-1 mt-4' name="" id="" placeholder={t('fotr_1_msj')}></textarea>
        </div>
        <button id='bttn' type="submit">{t('fotr_1_bttn')}</button>
      </div>
      <div className='footer-bottom flex flex-col text-white bg-black/[.9] md:px-20 px-4 pt-20'>
        <div className='md:grid md:grid-cols-5 md:gap-4 md:space-y-0 space-y-7'>
          <div className='md:col-span-2 md:px-3 space-y-1 text-sm'>
            <div className='flex flex-row items-center'>
              <FaLocationArrow className='min-w-5 mr-1' />
              <div>{t('con_loc')}</div>
            </div>
            <div className='flex flex-row items-center'>
              <FaPhone className='min-w-5 mr-1' />
              <div>{t('con_tel_1')}</div>
            </div>
            {/* <div className='flex flex-row items-center'>
              <FaPhone className='min-w-5 mr-1' />
              <div>{t('con_tel_2')}</div>
            </div> */}
            <div className='flex flex-row items-center'>
              <FaEnvelope className='min-w-5 mr-1' />
              <div className=''>{t('con_email')}</div>
            </div>
          </div>
          <div className='md:col-span-1 md:px-3 flex flex-col space-y-3'>
            <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/">{t('home_ttl')}</Link>
            <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/çalişma-alanlarimiz">{t('ow_ttl')}</Link>
            <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/hakkimizda">{t('au_ttl')}</Link>
            <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/iletisim">{t('con_ttl')}</Link>
          </div>
          <div className='md:col-span-2'>{t('fotr_3')}</div>
        </div>
        <div className='text-xs mt-10 py-6 text-center'>Copy Right © Rıfat Duran</div>
      </div>
    </div>
  )
}

export default Footer
