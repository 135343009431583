import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import Header from '../../comp/header2/header2.jsx'
import Footer from '../../comp/footer/footer.jsx'
import "./blog_comp.css"
import { useTranslation } from 'react-i18next'

function UseGetdata() {
    const [data, setData] = useState([]);
    return [data, setData];
}

const Blog_comp = () => {
    const { t, i18n } = useTranslation()
    const { slug } = useParams();
    const [data, setData] = UseGetdata();

    useEffect(() => {
        window.scrollTo(0, 0); // Sayfa en başa kaydırılır
    }, []);

    useEffect(() => {
        fetch("../../../data.json")
            .then((response) => response.json())
            .then((jsonData) => {
                if (Array.isArray(jsonData.sections)) {
                    setData(jsonData.sections);
                } else {
                    console.error("Beklenen dizi formatında veri alınamadı");
                }
            })
            .catch((error) => console.error("Veri çekilirken hata oluştu:", error));
    }, [setData]);

    const items = [];

    function CreateItems(lan) {
        items.push(data.filter(item => item?.lang === lan))
    }

    CreateItems(i18n.language)
    const renderListItem = (item) => (
        (item.slug === slug)
            ? (<Link className='blog-navbar-link mb-0 text-white bg-red-950' to={`/çalişma-alanlarimiz/${item.slug}`}>{item.title}</Link>)
            : (<Link className='blog-navbar-link mb-0' to={`/çalişma-alanlarimiz/${item.slug}`}>{item.title}</Link>)
    )
    const getblog = (item) => (
        (item.slug === slug)
            ? (< div ><p className='md:text-6xl text-3xl md:mb-24'>{item.title.split('\n').map((line, index) => (<React.Fragment key={index}>{line}<br /></React.Fragment>))}</p><p className='md:text-2xl md:ml-5 md:pr-14 font-normal'>{item.text.split('\n').map((line, index) => (<React.Fragment key={index}>{line}<br /></React.Fragment>))}</p></div >)
            : ("")
    )

    return (
        <div>
            <Header />
            <div className='blog-comp py-24 md:px-24'>
                <div className='text-center text-3xl font-semibold'>{t('ow_ttl')}</div>
                <div className='flex flex-row space-x-5 md:space-x-20 px-4 md:px-0 pt-24'>
                    <div>
                        <div className=' md:min-w-max p-2 mb-3 border-b-2 text-3xl'>{t('ow_tll_2')}</div>
                        <div className='blog-navbar md:min-w-max flex flex-col md:space-y-2 pl-0'>
                            {items[0].map((item) => renderListItem(item))}
                        </div>
                    </div>
                    {items[0].map((item) => getblog(item))}
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Blog_comp