import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import TR_LOCAL from "./lngdata/tr.json"
import EN_LOCAL from "./lngdata/en.json"
import FR_LOCAL from "./lngdata/fr.json"

i18n
    .use(initReactI18next)
    .init({
        resources: {
            tr: {
                translation: TR_LOCAL
            },
            en:{
                translation: EN_LOCAL
            },
            fr:{
                translation: FR_LOCAL
            }
        },
        lng: "tr",
        fallbackLng: ["tr", "en", "fr"],

        interpolation: {
            escapeValue: false
        }
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

