import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from '../../comp/slider/slider.jsx'
import Header from '../../comp/header/header.jsx'
import Footer from '../../comp/footer/footer.jsx'
import './home.css'
import { FaUser, FaHome, FaPencilAlt, FaBuilding, FaHandshake, FaScroll, FaShieldAlt, FaPenNib, FaGlobe } from "react-icons/fa"
import { useTranslation } from 'react-i18next'

let index = 0

function UseHoveringState() {
  const [isHovering, setIsHovering,] = useState(false);
  return [isHovering, setIsHovering];
}

function handleMouseEnter(setIsHovering, i) {
  index = i
  setIsHovering(true);
}

function handleMouseLeave(setIsHovering, i) {
  index = i
  setIsHovering(false);
}

function crossClass(isHovering, i) {
  if (index === i) {
    return `cross-line ${isHovering ? "cross-line-hover" : ""}`;
  } else {
    return `cross-line`;
  }
}
function crossClassColor(isHovering, i) {
  if (index === i) {
    return `border-1 border-red-950 h-min p-3 mr-3   ${isHovering ? "bg-white text-red-950" : "bg-red-950 text-white"}`;
  } else {
    return `border-1 border-red-950 h-min p-3 mr-3  bg-red-950 text-white`
  }
}

function UseGetdata() {
  const [data, setData] = useState([]);
  return [data, setData];
}

const Home = () => {
  const { t, i18n } = useTranslation()
  const [isHovering, setIsHovering] = UseHoveringState();
  const [data, setData] = UseGetdata();

  useEffect(() => {
    window.scrollTo(0, 0); // Sayfa en başa kaydırılır
    fetch("../../../data.json")
      .then((response) => response.json())
      .then((jsonData) => {
        // JSON verisinin bir dizi olup olmadığını kontrol et
        if (Array.isArray(jsonData.sections)) {
          setData(jsonData.sections);
        } else {
          console.error("Beklenen dizi formatında veri alınamadı");
        }

      })
      .catch((error) => console.error("Veri çekilirken hata oluştu:", error));
  }, [setData, i18n]);

  const items = [];

  function CreateItems(lan) {
    items.push(data.filter(item => item?.lang === lan))
  }

  CreateItems(i18n.language)

  function GetIcon(index) {
    switch (index) {
      case 0:
        return <FaUser className='text-5xl' />;
      case 1:
        return <FaHome className='text-5xl' />;
      case 2:
        return <FaPencilAlt className='text-5xl' />;
      case 3:
        return <FaBuilding className='text-5xl' />;
      case 4:
        return <FaHandshake className='text-5xl' />;
      case 5:
        return <FaScroll className='text-5xl' />;
      case 6:
        return <FaShieldAlt className='text-5xl' />;
      case 7:
        return <FaPenNib className='text-5xl' />;
      case 8:
        return <FaGlobe className='text-5xl' />;

      default:
        break;
    }
  }
  const renderListItem = (item, index) => (
    <li className='flex flex-row'
      onMouseEnter={() => handleMouseEnter(setIsHovering, index)}
      onMouseLeave={() => handleMouseLeave(setIsHovering, index)}>
      <div>
        <div className={crossClassColor(isHovering, index)}>
          {GetIcon(index)}
        </div>
        <div className={crossClass(isHovering, index)}></div>
      </div>
      <div>
        <div id='fal-ttl' className='text-black mb-3 text-xl font-serif font-bold'>
          <p>{item ? (<p>{item.title}</p>) : (<p>Veri bulunamadı</p>)}</p>
        </div>
        <div id='fal-txt' className='text-black mb-4'>
          <p>{item ? (<p>{item.text_short}</p>) : (<p>Veri bulunamadı</p>)}</p>
        </div>
        <Link to={`/çalişma-alanlarimiz/${item.slug}`} className='link p-2 border-2 border-red-950 justify-center rounded w-20 h-10 text-center mt-10 text-red-950 hover:text-white hover:bg-red-950' >
          {t('home_2_bttn')}
        </Link>
      </div>
    </li>
  );

  return (
    <div>
      <Header />
      <Slider />
      <div className='md:grid md:grid-cols-2 md:grid-rows-none md:gap-4 p-10'>
        <div className='md:col-span-1'>
          <img className='shadow-md shadow-black w-auto' src="../../../img/library.jpg" alt="" />
        </div>
        <div className='md:col-span-1 md:ml-10 '>
          <div id='title' className='md:mb-10 my-10'>{t('au_ttl')}</div>
          <div id='text'>{t('home_1_txt_1')}, {t('home_1_txt_2')}</div>
        </div>
      </div>
      <div className='mt-10 px-10'>
        <div id='fal-tit' className='mb-20 text-center bold text-4xl text-zinc-600'>
          {t('home_2_ttl')}
        </div>
        <ul id='fal' className='flex flex-wrap gap-5 pl-0 justify-center'>
          {items[0].map((item, index) => renderListItem(item, index))}
        </ul>
      </div>
      <div id='nasıl-çalışıyoruz' className='mt-20 '>
        <div className='bg-black/[.6]  w-auto h-auto'>
          <div id='nçbt' className='py-20 pl-10 text-4xl'>{t('home_3_ttl')}</div>
          <div className='flex flex-wrap gap-5 justify-center'>
            <div className='w-96 md:p-2 px-8'>
              <div className='w-2 h-2 bg-red-950 rounded'></div>
              <div id='nçt'>{t('home_3_1_ttl')}</div>
              <div>{t('home_3_1_txt')}</div>
            </div>
            <div className='w-96 md:p-2 px-8'>
              <div className='w-2 h-2 bg-red-950 rounded'></div>
              <div id='nçt'>{t('home_3_2_ttl')}</div>
              <div>{t('home_3_2_txt')}</div>
            </div>
            <div className='w-96 md:p-2 px-8'>
              <div className='w-2 h-2 bg-red-950 rounded'></div>
              <div id='nçt'>{t('home_3_3_ttl')}</div>
              <div>{t('home_3_3_txt')}</div>
            </div>
            <div className='w-96 md:p-2 px-8'>
              <div className='w-2 h-2 bg-red-950 rounded'></div>
              <div id='nçt'>{t('home_3_4_ttl')}</div>
              <div>{t('home_3_4_txt')}</div>
            </div>
            <div className='w-96 md:p-2 px-8 mb-20'>
              <div className='w-2 h-2 bg-red-950 rounded'></div>
              <div id='nçt'>{t('home_3_5_ttl')}</div>
              <div>{t('home_3_5_txt')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='py-5'>
        <div className='flex flex-row items-center justify-center py-5'>
          {/* justify-around */}
          <img className='md:w-60 md:mr-14 w-48 pr-4' src="../../../img/istac.jpg" alt="" />
          <div className='w-0.5 h-40 bg-black/[.4] rounded'></div>
          <img className='md:w-60 md:ml-14 w-40 pl-4' src="../../../img/barobirlik_logo.jpeg" alt="" />
          {/* <div className='p-3 w-72'>
            <div className='items-bg'>
              <div className='bg-black/[.6] w-auto h-52'></div>
            </div>
            <div className='text-3xl text-center mt-10'>XXXXXXXX XXXXXXXXX</div>
          </div>
          <div className='p-1 w-72'>
            <div className='items-bg'>
              <div className='bg-black/[.6] w-auto h-52'></div>
            </div>
            <div className='text-3xl text-center mt-10'>XXXXXXXX XXXXXXXXX</div>
          </div>
          <div className='p-1 w-72'>
            <div className='items-bg'>
              <div className='bg-black/[.6] w-auto h-52'></div>
            </div>
            <div className='text-3xl text-center mt-10'>XXXXXXXX XXXXXXXXX</div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home