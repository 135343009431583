import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../comp/header2/header2.jsx'
import Footer from '../../comp/footer/footer.jsx'
import './our-works.css'
import { useTranslation } from 'react-i18next'

function UseGetdata() {
  const [data, setData] = useState([]);
  return [data, setData];
}

const Ourworks = () => {
  const { t, i18n } = useTranslation()
  const [data, setData] = UseGetdata();

  useEffect(() => {
    window.scrollTo(0, 0); // Sayfa en başa kaydırılır
  }, []);

  useEffect(() => {
    fetch("../../../data.json")
      .then((response) => response.json())
      .then((jsonData) => {
        if (Array.isArray(jsonData.sections)) {
          setData(jsonData.sections);
        } else {
          console.error("Beklenen dizi formatında veri alınamadı");
        }
      })
      .catch((error) => console.error("Veri çekilirken hata oluştu:", error));
  }, [setData]);

  const items = [];

  function CreateItems(lan) {
    items.push(data.filter(item => item?.lang === lan))
  }
  CreateItems(i18n.language)

  const renderListItem = (item) => (
    <li className='flex flex-col transition-transform duration-300 transform hover:scale-110'>
      <div className='item-top-img'>
        <div className='item-all flex flex-col justify-center items-center bg-black/[.5] w-auto h-52 text-white text-center p-3'>
          <p className='text-3xl mb-0'>{item.title}</p>
          <Link to={`/çalişma-alanlarimiz/${item.slug}`} className='link text-white decoration-none p-1.5 border-2 border-red-950 w-20 h-10 justify-center items-center text-center rounded '>
            {t('home_2_bttn')}
          </Link>
        </div>
      </div>
    </li>
  )

  return (
    <div>
      <Header />
      <div className='ourworks py-24'>
        <div className='text-center text-3xl font-semibold'>{t('ow_ttl')}</div>
        <ul className='fal flex flex-wrap gap-5 justify-center pt-24 md:px-20 pr-8'>
          {items[0].map((item) => renderListItem(item))}
        </ul>
      </div>
      <Footer />
    </div>
  )
}

export default Ourworks
