import React, {useEffect} from 'react'
import Header from '../../comp/header2/header2.jsx'
import Footer from '../../comp/footer/footer.jsx'
import './aboutus.css'
import { useTranslation } from 'react-i18next'

const Aboutus = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0); // Sayfa en başa kaydırılır
  }, []);

  return (
    <div>
      <Header />
      <div className='aboutus py-24'>
        <div className='text-center text-3xl font-semibold'>{t('au_ttl')}</div>
        <div className='flex flex-col md:flex-row md:py-24 pt-24 md:px-32 px-4 md:space-x-10'>
        <img className='md:hidden' src="./img/library.jpg" alt="" />
          <div className=''>
            <div className='md:text-xl text-3xl mb-3 mt-10 font-medium'>{t('home_1_txt_1')}</div>
            <div>{t('home_1_txt_2')}</div>
            <div className='text-xl my-3 font-medium'>{t('con_ttl')}</div>
            <div>
              {/* <div>{t('con_ttl')}</div>  */}
              <div>{t('con_tel_1')}</div>
              {/* <div>{t('con_tel_2')}</div> */}
              <div>{t('con_email')}</div>
            </div>
          </div>
          <img className='hidden md:block' src="./img/library.jpg" alt="" />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Aboutus
