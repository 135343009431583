import Home from './pages/home/home.jsx'
import AboutUs from './pages/aboutus/aboutus.jsx'
import Contact from './pages/contact/contact.jsx'
import OurWorks from './pages/our-works/our-works.jsx'
import BlogComp from './pages/blog_comp/blog_comp.jsx'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route} from 'react-router-dom';
function App() {
  return (
    <div>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/hakkimizda" element={<AboutUs/>}/>
          <Route path="/iletisim" element={<Contact/>}/>
          <Route path="/çalişma-alanlarimiz" element={<OurWorks/>}/>
          <Route path="/çalişma-alanlarimiz/:slug" element={<BlogComp />} />
        </Routes>
    </div>
  );
}

export default App;
