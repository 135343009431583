import React, { useState } from 'react'
import './header2.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next'

function UseClickState() {
    const [isClick, setIsClick,] = useState(false);
    return [isClick, setIsClick,];
}
function SlideBarOpen(setIsClick) {
    setIsClick(true);
}
function SlideBarClose(setIsClick) {
    setIsClick(false);
}

function Clicked(isClick) {
    if (isClick === true) {
        return `sidebar md:hidden flex flex-col items-start justify-start h-full w-screen py-10 right-0 top-0 z-10 bg-white/[.3] fixed`
    } else {
        return `sidebar hidden flex flex-col items-start justify-start h-full w-screen py-10 right-0 top-0 z-10 bg-white/[.3] fixed`
    }
}
const Header2 = () => {
    const [isClick, setIsClick,] = UseClickState();
    const { t, i18n } = useTranslation()

    return (
        <div className='right-0.5 z-2 sticky top-0 w-screen px-10 bg-black/[.5]'>
            <div className='flex flex-row items-center h-32 justify-between md:pl-10'>
                <button><Link to="/"><img className='w-32' src="../../../img/logo.png" alt="" /></Link></button>
                <div className={Clicked(isClick)}>
                    <div className='w-full px-10 item-center flex flex-row betwen'>
                        <div className='mr-auto flex flex-row space-x-3 w-30'>
                            <button onClick={() => i18n.changeLanguage("tr")}><img href="https://www.youtube.com/" className='w-12' src="../../../img/tr.png" alt="" /></button>
                            <button onClick={() => i18n.changeLanguage("en")}><img href="https://www.youtube.com/" className='w-12' src="../../../img/eng.png" alt="" /></button>
                            <button onClick={() => i18n.changeLanguage("fr")}><img href="https://www.youtube.com/" className='w-12' src="../../../img/fr.png" alt="" /></button>
                        </div>
                        <button>
                            <FontAwesomeIcon onClick={(e) => SlideBarClose(setIsClick)} icon={faXmark} className='text-white h-10 w-10' />
                        </button>
                    </div>
                    <Link id='sb-navbar-item' style={{ textDecoration: 'none' }} to="/">{t('home_ttl')}</Link>
                    <Link id='sb-navbar-item' style={{ textDecoration: 'none' }} to="/çalişma-alanlarimiz">{t('ow_ttl')}</Link>
                    <Link id='sb-navbar-item' style={{ textDecoration: 'none' }} to="/hakkimizda">{t('au_ttl')}</Link>
                    <Link id='sb-navbar-item' style={{ textDecoration: 'none' }} to="/iletisim">{t('con_ttl')}</Link>
                </div>
                <div className='md:flex hidden items-center'>
                    <div className='flex flex-row list-none ml-auto text-xl space-x-12'>
                        <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/">{t('home_ttl')}</Link>
                        <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/çalişma-alanlarimiz">{t('ow_ttl')}</Link>
                        <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/hakkimizda">{t('au_ttl')}</Link>
                        <Link id='navbar-item' style={{ textDecoration: 'none' }} to="/iletisim">{t('con_ttl')}</Link>
                    </div>
                    <div className='flex flex-col w-30 my-2 ml-10'>
                        <button onClick={() => i18n.changeLanguage("tr")}><img href="https://www.youtube.com/" className='w-6 mb-1 ' src="../../../img/tr.png" alt="" /></button>
                        <button onClick={() => i18n.changeLanguage("en")}><img href="https://www.youtube.com/" className='w-6 mb-1' src="../../../img/eng.png" alt="" /></button>
                        <button onClick={() => i18n.changeLanguage("fr")}><img href="https://www.youtube.com/" className='w-6' src="../../../img/fr.png" alt="" /></button>
                    </div>
                </div>
                <div className='md:hidden flex'>
                    <FontAwesomeIcon onClick={(e) => SlideBarOpen(setIsClick)} icon={faBars} className='text-white h-10 w-10' />
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Header2
