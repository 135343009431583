import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './slider.css'
import { useTranslation } from 'react-i18next'

function ControlledCarousel() {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation()

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className='-translate-y-32'>

      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <div className='fslide d-block w-100 h-screen' alt="First slide" >
            <div className='bg-black/[.3] w-100 h-100'>
            </div>
          </div>
          <Carousel.Caption>
            <h3>{t('sld_1_ttl')}</h3>
            {/* <p>{t('sld_1_txt')}</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className='sslide d-block w-100 h-screen' alt="Second slide" >
            <div className='bg-black/[.5] w-100 h-100'>
            </div>
          </div>
          <Carousel.Caption>
            <h3>{t('sld_2_ttl')}</h3>
            {/* <p>{t('sld_2_txt')}</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className='tslide d-block w-100 h-screen' alt="Third slide" >
            <div className='bg-black/[.5] w-100 h-100'>
            </div>
          </div>
          <Carousel.Caption>
            <h3>{t('sld_3_ttl')}</h3>
            {/* <p>{t('sld_3_txt')}</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default ControlledCarousel;