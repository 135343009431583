import React, { useEffect } from 'react'
import Header from '../../comp/header2/header2.jsx'
import Footer from '../../comp/footer/footer.jsx'
import './contact.css'
import { FaPhone, FaEnvelope, FaLocationArrow } from "react-icons/fa"
import Iframe from 'react-iframe'
import { useTranslation } from 'react-i18next'

const Contact = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0); // Sayfa en başa kaydırılır
  }, []);

  return (
    <div>
      <Header />
      {/* <div className='top-img w-screen h-48 -translate-y-20 sticky top-0'></div> */}
      <div className='contact flex flex-col items-center md:px-10 px-4 py-24'>
        <div className='text-3xl font-semibold'>{t('con_ttl')}</div>
        <div className='md:grid md:grid-cols-2 md:px-3 space-y-12 md:space-y-0 pt-24'>
          <div className='md:hidden'>
            <Iframe className='w-100 h-52' frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=500&amp;height=500&amp;hl=en&amp;q=Kurtulu%C5%9F,%20Pazaryeri%20Sk.%202%20D,%2026090%20Odunpazar%C4%B1/Eski%C5%9Fehir+(duran%20hukuk%20b%C3%BCrosu)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></Iframe>
          </div>
          <div className='md:col-span-1 md:flex md:flex-col justify-center px-3 space-y-1 text-lg md:text-sm'>
            <div className='flex flex-row items-center'>
              <FaLocationArrow className='min-w-5 mr-3' />
              <div className=''>{t('con_loc')}</div>
            </div>
            <div className='flex flex-row items-center'>
              <FaPhone className='min-w-5 mr-3' />
              <div>{t('con_tel_1')}</div>
            </div>
            {/* <div className='flex flex-row items-center'>
              <FaPhone className='min-w-5 mr-3' />
              <div>{t('con_tel_2')}</div>
            </div> */}
            <div className='flex flex-row items-center'>
              <FaEnvelope className='min-w-5 mr-3' />
              <div className=''>{t('con_email')}</div>
            </div>
          </div>
          <div className='hidden md:block'>
            <Iframe className='col-span-1' width="500" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=500&amp;height=500&amp;hl=en&amp;q=Kurtulu%C5%9F,%20Pazaryeri%20Sk.%202%20D,%2026090%20Odunpazar%C4%B1/Eski%C5%9Fehir+(duran%20hukuk%20b%C3%BCrosu)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></Iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
